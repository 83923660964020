import Image from 'next/image'
import clsx from 'clsx'

import Link from '@/atoms/Link/Link'

/* utils */
import {
  PlatformDropdownProps,
  SolutionsDropdownProps,
  ResourceDropdownProps
} from './Header.utils'

/* assets */
import { ReactComponent as ArrowLeft } from 'public/assets/icons/chevron-right.svg'
import { ReactComponent as ForwardArrow } from 'public/assets/cmp/navigation/icons/forward-arrow.svg'

export function PlatformDropdown({
  name,
  links,
  image,
  description,
  features
}: PlatformDropdownProps): JSX.Element {
  return (
    <section className="max-header-width | grid lg:grid-cols-12 lg:gap-8 gap-4 | bg-white | lg:pl-22 mx-auto">
      <div className="lg:col-span-4 | text-gray-90 | lg:pt-14 lg:pb-14 lg:px-0 p-6">
        <div className="mb-5 | flex lg:flex-col items-center lg:items-start justify-between | gap-5 lg:gap-0">
          <p className="font-medium text-2xl mb-5">{name}</p>
          {image ? (
            <Image
              src={image.url}
              alt={image.alternativeText ?? ''}
              height={image.height}
              width={image.width}
              className="flex-none max-w-full"
            />
          ) : null}
        </div>
        <p className="text-lg | mb-5">{description}</p>
        <p className="font-medium text-xs text-blue-50 | flex items-center | group hover:underline decoration-2 underline-offset-4">
          <Link
            href="/product/content-marketing-platform/"
            rel="noopener noreferrer"
          >
            Read More
          </Link>
          <ArrowLeft className="ml-3 | group-hover:translate-x-1 transition duration-150 ease-linear" />
        </p>
      </div>
      <div className="lg:col-span-5 lg:justify-self-center | lg:pt-14 lg:pb-20 px-2 lg:px-6">
        {links.map(item => (
          <Link
            key={item.slug}
            href={item.slug}
            className="rounded-xl | flex items-center | p-4 | group transition-colors hover:text-blue-50 hover:bg-gray-20 | focus:outline-none focus:bg-gray-20"
          >
            <Image
              src={item.icon}
              alt="icon"
              width="32"
              height="32"
              className="w-8 h-8"
              loading="lazy"
            />
            <div className="ml-6 ">
              <div className="font-medium text-base lg:text-lg text-gray-90 group-hover:text-blue-50 | flex items-center | mb-1">
                {item.title}
                <ForwardArrow className="ml-2 | font-semibold | group-hover:translate-x-1 transition duration-150 ease-linear" />
              </div>
              <p className="text-xs text-gray-60">{item.description}</p>
            </div>
          </Link>
        ))}
      </div>
      <div className="lg:col-span-3 | font-medium | lg:pl-8 lg:pt-12 px-8 py-6 | bg-gray-10">
        <p className="mb-5 text-small text-gray-50">Top Features</p>
        {features.map(feature => (
          <Link
            key={feature.slug}
            href={feature.slug}
            className="block font-medium text-sm | mb-3 | text-gray-90 hover:text-blue-50 focus:text-blue-50 focus:outline-none"
          >
            {feature.title}
            {feature.new ? (
              <span className="bg-yellow-40 text-small | ml-1.5 px-1.5 py-1 | rounded">
                New
              </span>
            ) : null}
          </Link>
        ))}
      </div>
    </section>
  )
}

export function SolutionsDropdown({
  links,
  industries,
  others
}: SolutionsDropdownProps): JSX.Element {
  return (
    <section className="max-header-width | grid lg:grid-cols-12 gap-4 lg:gap-8 | lg:pl-22 mx-auto | bg-white">
      <div className="grid lg:grid-cols-8 lg:col-span-8 | lg:py-14  pt-4">
        <p className="lg:col-span-8 | font-semibold text-small text-gray-50 uppercase | mb-4 px-6">
          By Use Cases
        </p>
        <div className="lg:col-span-4 | lg:pr-8 px-2 lg:px-6 | order-2 lg:order-1">
          {links &&
            links.slice(0, Math.ceil(links.length / 2)).map(item => (
              <Link
                key={item.slug}
                href={item.slug}
                className="rounded-xl | flex items-center | p-4 | group transition-colors | hover:text-blue-50 hover:bg-gray-20 focus:outline-none focus:bg-gray-20"
              >
                <Image
                  src={item.icon}
                  alt="icon"
                  width="32"
                  height="32"
                  className="w-8 h-8"
                  loading="lazy"
                />
                <div className="ml-6 ">
                  <div className="font-medium text-base lg:text-lg text-gray-90  flex items-center | mb-1 | group-hover:text-blue-50">
                    {item.title}
                    <span>
                      <ForwardArrow className="ml-2 | font-semibold | group-hover:translate-x-1 transition duration-150 ease-linear" />{' '}
                    </span>
                  </div>
                  <p className="text-xs text-gray-60">{item.description}</p>
                </div>
              </Link>
            ))}
        </div>
        <div className="lg:col-span-4 |  lg:pr-8 px-2 lg:px-6 | order-1 lg:order-2">
          {links &&
            links.slice(Math.ceil(links.length / 2), links.length).map(item => (
              <Link
                key={item.slug}
                href={item.slug}
                className="rounded-xl | flex items-center | p-4 | group transition-colors | hover:text-blue-50 hover:bg-gray-20 focus:outline-none focus:bg-gray-20"
              >
                <Image
                  src={item.icon}
                  alt="icon"
                  width="32"
                  height="32"
                  className="w-8 h-8"
                  loading="lazy"
                />
                <div className="ml-6 ">
                  <div className="font-medium text-lg text-gray-90 | flex items-center | mb-1 | group-hover:text-blue-50">
                    {item.title}
                    <span>
                      <ForwardArrow className="ml-2 | font-semibold | group-hover:translate-x-1 transition duration-150 ease-linear" />{' '}
                    </span>
                  </div>
                  <p className="text-xs text-gray-60">{item.description}</p>
                </div>
              </Link>
            ))}
        </div>
      </div>

      <div className="lg:col-span-4 grid grid-flow-col | order-3 bg-gray-10">
        <div className="col-span-2 | font-medium | lg:pt-12 lg:pb-14 p-8">
          <p className="mb-5 | text-small text-gray-50 uppercase">
            By Industries
          </p>
          {industries.map(feature => (
            <Link
              key={feature.slug}
              href={feature.slug}
              className="block font-medium text-sm | mb-3 |  text-gray-90 hover:text-blue-50 focus:text-blue-50 focus:outline-none"
            >
              {feature.title}
            </Link>
          ))}
        </div>
        <div className="col-span-2 | font-medium | lg:pt-12 lg:pb-14 lg:pl-8 p-8">
          <p className="mb-5 | text-small text-gray-50 uppercase">
            By Content Goals
          </p>
          {others.map(feature => (
            <Link
              key={feature.slug}
              href={feature.slug}
              className="block font-medium text-sm | mb-3 |  text-gray-90 hover:text-blue-50 focus:text-blue-50 focus:outline-none"
            >
              {feature.title}
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

export function ResourcesDropdown({
  library,
  resources,
  description,
  image
}: ResourceDropdownProps): JSX.Element {
  return (
    <section
      className={clsx(
        'max-header-width | grid grid-cols-4 lg:grid-cols-12 | gap-4 lg:gap-8 | lg:pl-22 mx-auto | bg-white'
      )}
    >
      <div className="col-span-4 | px-2 lg:px-6 pt-4 lg:pt-10 lg:pb-14">
        <p className="font-semibold text-small text-gray-50 uppercase | mb-2 lg:mb-4 px-6">
          Library
        </p>
        {library.map(item => (
          <Link
            key={item.slug}
            href={item.slug}
            target={item.external ? '_blank' : '_self'}
            rel={item.external ? 'noopener noreferrer' : ''}
            className="rounded-xl | flex items-center | p-4 | group transition-colors | hover:text-blue-50 hover:bg-gray-20 focus:outline-none focus:bg-gray-20"
          >
            <Image
              src={item.icon}
              alt="icon"
              width="32"
              height="32"
              className="w-8 h-8"
              loading="lazy"
            />
            <div className="ml-6 ">
              <div className="font-medium text-lg text-gray-90 | flex items-center | mb-1 | group-hover:text-blue-50">
                {item.title}
                <span>
                  <ForwardArrow className="ml-2 | font-semibold | group-hover:translate-x-1 transition duration-150 ease-linear" />
                </span>
              </div>
              <p className="text-xs text-gray-60">{item.description}</p>
            </div>
          </Link>
        ))}
      </div>
      <div className="col-span-4 |  px-2 lg:px-6 lg:pt-10 lg:pb-14">
        <p className="font-semibold text-small text-gray-50 uppercase | mb-2 lg:mb-4 px-6">
          Resources
        </p>
        {resources.map(item => (
          <Link
            key={item.slug}
            href={item.slug}
            target={item.external ? '_blank' : '_self'}
            rel={item.external ? 'noopener noreferrer' : ''}
            className="rounded-xl | flex items-center | p-4 | group transition-colors | hover:text-blue-50 hover:bg-gray-20 focus:outline-none focus:bg-gray-20"
          >
            <Image
              src={item.icon}
              alt="icon"
              width="32"
              height="32"
              className="w-8 h-8"
              loading="lazy"
            />
            <div className="ml-6 ">
              <div className="font-medium text-lg text-gray-90 | flex items-center | mb-1 | group-hover:text-blue-50">
                {item.title}
                <span>
                  <ForwardArrow className="ml-2 | font-semibold | group-hover:translate-x-1 transition duration-150 ease-linear" />
                </span>
              </div>
              <p className="text-xs text-gray-60">{item.description}</p>
            </div>
          </Link>
        ))}
      </div>

      <div className="col-span-4 text-gray-90 | lg:pt-10 lg:pb-14 lg:pr-22 lg:pl-8 px-6 py-8 | bg-gray-10">
        <p className="font-semibold text-small text-gray-50 uppercase | mb-8">
          Updates
        </p>
        <Image
          src={image.url}
          alt={image.alternativeText ?? ''}
          height={image.height}
          width={image.width}
          className="mb-5"
        />
        <p className="text-lg | mb-5">{description}</p>
        <p className="font-medium text-xs text-blue-50 | flex items-center | group hover:underline decoration-2 underline-offset-4">
          <Link href="/global-marketing-leaders/">Learn More</Link>
          <ArrowLeft className="ml-3 | group-hover:translate-x-1 transition duration-150 ease-linear" />
        </p>
      </div>
    </section>
  )
}

export function TalentNetworkDropdown({
  name,
  links,
  image,
  description,
  features
}: PlatformDropdownProps): JSX.Element {
  return (
    <section className="max-header-width | grid lg:grid-cols-12 lg:gap-8 gap-4 | bg-white | lg:pl-22 mx-auto">
      <div className="lg:col-span-4 | text-gray-90 | lg:pt-14 lg:pb-14 lg:px-0 p-6">
        <div className="mb-5 | flex lg:flex-col items-center lg:items-start justify-between | gap-5 lg:gap-0">
          <p className="font-medium text-2xl mb-5">{name}</p>
          {image ? (
            <Image
              src={image.url}
              alt={image.alternativeText ?? ''}
              height={image.height}
              width={image.width}
              className="flex-none max-w-full"
            />
          ) : null}
        </div>
        <p className="text-lg | mb-5">{description}</p>
        <p className="font-medium text-xs text-blue-50 | flex items-center | group hover:underline decoration-2 underline-offset-4">
          <Link href="/product/talent-marketplace/" rel="noopener noreferrer">
            Read More
          </Link>
          <ArrowLeft className="ml-3 | group-hover:translate-x-1 transition duration-150 ease-linear" />
        </p>
      </div>
      <div className="lg:col-span-5 lg:justify-self-center | lg:pt-14 lg:pb-20 px-2 lg:px-6">
        {links.map(item => (
          <Link
            key={item.slug}
            href={item.slug}
            className="rounded-xl | flex items-center | p-4 | group transition-colors hover:text-blue-50 hover:bg-gray-20 | focus:outline-none focus:bg-gray-20"
          >
            <Image
              src={item.icon}
              alt="icon"
              width="32"
              height="32"
              className="w-8 h-8"
              loading="lazy"
            />
            <div className="ml-6 ">
              <div className="font-medium text-base lg:text-lg text-gray-90 group-hover:text-blue-50 | flex items-center | mb-1">
                {item.title}
                <ForwardArrow className="ml-2 | font-semibold | group-hover:translate-x-1 transition duration-150 ease-linear" />
              </div>
              <p className="text-xs text-gray-60">{item.description}</p>
            </div>
          </Link>
        ))}
      </div>
      <div className="lg:col-span-3 | font-medium | lg:pl-8 lg:pt-12 px-8 py-6 | bg-gray-10">
        <p className="mb-5 text-small text-gray-50">Few Content Services</p>
        {features.map(feature => (
          <Link
            key={feature.slug}
            href={feature.slug}
            className="block font-medium text-sm | mb-3 |  text-gray-90 hover:text-blue-50 focus:text-blue-50 focus:outline-none"
          >
            {feature.title}
            {feature.new ? (
              <span className="bg-yellow-40 text-small | ml-1.5 px-1.5 py-1 | rounded">
                New
              </span>
            ) : null}
          </Link>
        ))}
      </div>
    </section>
  )
}
