export interface HeaderProps {
  className?: string
  primaryCta?: { title: string; url: string }
  secondaryCta?: { title: string; url: string }
  tertiaryCta?: { title: string; url: string }
  showNavLinks?: boolean
  mode: 'dark' | 'light'
  platformData: PlatformDropdownProps
  solutionsData: SolutionsDropdownProps
  resourcesData: ResourceDropdownProps
  talentNetworkData: TalentNetworkDropdownProps
}

// data

export interface PlatformDropdownProps {
  name: string
  description: string
  image: StrapiImageFile
  links: {
    title: string
    description: string
    icon: string
    slug: string
  }[]
  features: { title: string; slug: string; new?: boolean }[]
}

export interface TalentNetworkDropdownProps {
  name: string
  description: string
  image: StrapiImageFile
  links: {
    title: string
    description: string
    icon: string
    slug: string
  }[]
  features: { title: string; slug: string; new?: boolean }[]
}

export interface SolutionsDropdownProps {
  links: {
    title: string
    description: string
    icon: string
    slug: string
  }[]
  industries: { title: string; slug: string }[]
  others: { title: string; slug: string }[]
}

export interface ResourceDropdownProps {
  description: string
  image: StrapiImageFile
  library: {
    title: string
    description: string
    icon: string
    slug: string
    external?: boolean
  }[]
  resources: {
    title: string
    description: string
    icon: string
    slug: string
    external?: boolean
  }[]
}

export const platformData = {
  name: 'Content Marketing Platform',
  description:
    'Pepper’s platform brings data, teams and content together to prove and improve content RoI',
  image: {
    url: '/assets/cmp/navigation/images/content_marketing_platform.png',
    alternativeText: '',
    height: 128,
    width: 200
  },
  links: [
    {
      title: 'Content OS',
      slug: '/product/content-os/',
      description:
        'Streamline content creation, publishing and analysis for improved online presence',
      icon: '/assets/cmp/navigation/icons/operations.svg'
    },
    {
      title: 'Organic Marketing Stack',
      slug: '/product/organic-marketing-stack',
      description: 'Drive organic growth full suite of SEO and content tools',
      icon: '/assets/cmp/navigation/icons/organic-marketing-stack.svg'
    },
    {
      title: 'Marketplace',
      slug: '/product/talent-marketplace/',
      description:
        'Effortless Project Outsourcing: Hire top talent, streamline workflow, reach your goals.',
      icon: '/assets/cmp/navigation/icons/marketplace.svg'
    }
  ],
  features: [
    {
      title: 'Pepper AI',
      slug: '/pepper-ai/'
    },
    {
      title: 'Content Idea lab',
      slug: '/features/content-idea-generator/',
      new: true
    },
    {
      title: 'Content Editor',
      slug: '/features/seo-content-editor/',
      new: true
    },
    {
      title: 'Content Audit',
      slug: '/features/content-audit/',
      new: true
    },
    {
      title: 'Content RoI and Analytics',
      slug: '/features/measure-content-roi/',
      new: true
    },
    {
      title: 'Content Grader',
      slug: '/features/content-grader/',
      new: true
    }
  ]
}

export const solutionsData = {
  name: 'Content Marketing Platform',
  links: [
    {
      title: 'Scaling Content Creation',
      slug: '/use-case/scale-content-creation/',
      type: 'text',
      description:
        'Generate, edit and improve content using power of AI and Humans',
      icon: '/assets/cmp/navigation/icons/status-up.svg'
    },
    {
      title: 'Improve Productivity',
      slug: '/use-case/improve-productivity/',
      type: 'text',
      description: 'Boost your content production with access to new-age tools',
      icon: '/assets/cmp/navigation/icons/star.svg'
    },
    {
      title: 'Reducing CAC',
      slug: '/use-case/reducing-cac/',
      type: 'text',
      description:
        'Drive exponential growth from content strategies empowered by data',
      icon: '/assets/cmp/navigation/icons/direct-inbox.svg'
    },
    {
      title: 'Track Content RoI',
      slug: '/use-case/track-content-roi/',
      type: 'text',
      description:
        'Prove and improve your content performance by tracking its lifecycle',
      icon: '/assets/cmp/navigation/icons/lamp-charge.svg'
    }
  ],
  industries: [
    {
      title: 'BFSI',
      slug: '/industries/bfsi/'
    },
    {
      title: 'B2B-SaaS',
      slug: '/industries/b2b-saas/'
    },
    {
      title: 'Healthcare',
      slug: '/industries/healthcare/'
    },
    {
      title: 'Consumer Tech',
      slug: '/industries/consumer-tech/'
    },
    {
      title: 'FMCG',
      slug: '/industries/fmcg-cpg/'
    },
    {
      title: 'Others',
      slug: '/industries/other-industries/'
    }
  ],
  others: [
    {
      title: 'Organic Growth',
      slug: '/use-case/organic-traffic/'
    },
    {
      title: 'Demand Generation',
      slug: '/use-case/demand-gen/'
    },
    {
      title: 'Engage with Video',
      slug: '/video-services/'
    },
    {
      title: 'Localised Content',
      slug: '/language-services/'
    }
  ]
}

export const resourcesData = {
  library: [
    {
      title: 'Blogs',
      description:
        'Learn about content marketing and technology to get ahead of the curve',
      icon: '/assets/cmp/navigation/icons/quote-down-circle.svg',
      slug: '/blog/'
    },
    {
      title: 'Case studies',
      description:
        'Uncover how our customers drive revenue with Pepper Content',
      icon: '/assets/cmp/navigation/icons/case-study.svg',
      slug: '/customers/'
    },
    {
      title: 'Ebooks & Whitepapers',
      description: 'Read about latest trends, insights related to marketing',
      icon: '/assets/cmp/navigation/icons/ebook-whitepaper.svg',
      slug: '/resources/'
    }
  ],
  resources: [
    {
      title: 'Help Center',
      description: 'Learn how to install, set up and use Pepper CMP',
      icon: '/assets/cmp/navigation/icons/help-center.svg',
      slug: 'https://intercom.help/peppercontent/en',
      external: true
    },
    {
      title: 'Creator Hub',
      description: 'Learn more about using Pepper for Creator platform.',
      icon: '/assets/cmp/navigation/icons/creator-hub.svg',
      slug: 'https://peppercontent.freshdesk.com/support/home',
      external: true
    },
    {
      title: 'Events',
      description:
        'Join us for in-person and virtual events covering topics like content marketing, Gen AI, and beyond.',
      icon: '/assets/cmp/navigation/icons/calendar-2.svg',
      slug: 'https://peppercontent.io/events',
      external: true
    }
  ],
  description:
    'Hear top global marketing leaders talk about content and marketing.',
  image: {
    url: '/assets/cmp/navigation/images/global-marketing-leaders.png',
    alternativeText: '',
    height: 128,
    width: 200
  }
}

export const talentNetworkData = {
  name: 'Expert Talent Network',
  description: 'Build your dream content team with our industry experts',
  image: {
    url: '/assets/cmp/navigation/images/expert_talent_network.png',
    alternativeText: '',
    height: 128,
    width: 200
  },
  links: [
    {
      title: 'Top 3% Talent',
      slug: '/blog/editorial-excellence-how-we-find-the-best-writers-on-our-platform/',
      description: 'How we make sure you hire top 3% freelance talent',
      icon: '/assets/cmp/navigation/icons/operations.svg'
    },
    {
      title: 'Apply as Creator',
      slug: '/product/creator/',
      description:
        'Become part of Pepper’s Expert Network and work with world-class brands',
      icon: '/assets/cmp/navigation/icons/marketplace.svg'
    }
  ],
  features: [
    {
      title: 'Video Production',
      slug: '/video-services/'
    },
    {
      title: 'Localisation',
      slug: '/language-services/'
    },
    {
      title: 'Blog Writing',
      slug: '/writing-services/blog-writing-services/'
    },
    {
      title: 'Whitepaper Content',
      slug: '/writing-services/white-paper-writing/'
    },
    {
      title: 'Thought Leadership Content',
      slug: '/writing-services/thought-leadership-writing-services/'
    },
    {
      title: 'Subtitling',
      slug: '/language-services/subtitling/'
    },
    {
      title: 'Voice Over',
      slug: '/video-services/voice-overs-and-dubbing/'
    },
    {
      title: 'Infographic',
      slug: '/en/design-services/infographic/'
    }
  ]
}
