import { ReactText, useEffect, useState } from 'react'
import clsx from 'clsx'

import Link from '@/atoms/Link/Link'
import Accordion from '@/atoms/Accordion/Accordion'

import {
  PlatformDropdown,
  SolutionsDropdown,
  ResourcesDropdown,
  TalentNetworkDropdown
} from './dropdowns'

/*utils */
import { HeaderProps as Props } from './Header.utils'
import {
  dataLayerCtaClickEvent,
  dataLayerNavigationClickEvent
} from '@/utils/functions/dataLayerEvents'

/* assets */
import { ReactComponent as PepperLogo } from 'public/assets/icons/PepperLogo.svg'

/* styling */
import styles from './mobile.module.css'

function MobileHeader({
  showNavLinks = true,
  className,
  primaryCta,
  secondaryCta,
  platformData,
  solutionsData,
  resourcesData,
  talentNetworkData
}: Props): JSX.Element {
  const [menuVisibility, setMenuVisibility] = useState(false)
  const [activeAccordion, setActiveAccordion] = useState<string | number>()

  useEffect(() => {
    if (menuVisibility) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
      setActiveAccordion('')
    }
  }, [menuVisibility])

  useEffect(() => {
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/#css-custom-properties-the-trick-to-correct-sizing
    const getViewportHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    getViewportHeight()

    if (window.innerWidth <= 768) {
      window.addEventListener('resize', getViewportHeight)
    }
    return () => window.removeEventListener('resize', getViewportHeight)
  }, [])

  const toggleNav = () => setMenuVisibility(!menuVisibility)

  const handleAccordionChange = (key?: ReactText, isOpen?: boolean) => {
    if (isOpen) setActiveAccordion(key)
    else setActiveAccordion('')
  }

  return (
    <section
      className={clsx(
        'sticky top-0  |  h-18  |  px-5 mx-auto  |  bg-white  |  z-50',
        menuVisibility && 'active',
        className
      )}
    >
      <div className="flex justify-between items-center | h-full">
        {showNavLinks ? (
          <Link
            href="/"
            className="pt-4 mb-6"
            title="Homepage"
            onClick={() =>
              dataLayerNavigationClickEvent({ navigationMenuName: 'Logo' })
            }
          >
            <PepperLogo style={{ width: 169, height: 32 }} />
          </Link>
        ) : (
          <PepperLogo
            className="pt-4 mb-6"
            style={{ width: 169, height: 32 }}
          />
        )}
        {/* prettier-ignore */}
        <div className={clsx(styles.hamburger, menuVisibility && 'active', !showNavLinks && 'hidden')} role="button" aria-label="Toggle Navigation" tabIndex={0} id="hamburger" onClick={toggleNav} onKeyPress={toggleNav}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      {/* menu items */}
      <nav
        className={clsx(
          'py-4 | bg-white | select-none | z-50 | overflow-y-auto',
          menuVisibility && 'active',
          styles.mobileNavDropdown
        )}
      >
        <Accordion
          className="accordion--lg font-heading"
          activeKey={activeAccordion}
          elementKey="Platform"
          title="Platform"
          handleChange={handleAccordionChange}
          titleClass={styles.mobileNavTitle}
        >
          <PlatformDropdown {...platformData} />
        </Accordion>

        <Accordion
          className="accordion--lg font-heading"
          activeKey={activeAccordion}
          elementKey="Solutions"
          title="Solutions"
          handleChange={handleAccordionChange}
          titleClass={styles.mobileNavTitle}
        >
          <SolutionsDropdown {...solutionsData} />
        </Accordion>

        <Accordion
          className="accordion--lg font-heading"
          activeKey={activeAccordion}
          elementKey="Resources"
          title="Resources"
          handleChange={handleAccordionChange}
          titleClass={styles.mobileNavTitle}
        >
          <ResourcesDropdown {...resourcesData} />
        </Accordion>

        {/*<Link*/}
        {/*  href="/pricing/"*/}
        {/*  className={clsx('font-heading', styles.menuItem)}*/}
        {/*  onClick={() =>*/}
        {/*    dataLayerNavigationClickEvent({ navigationMenuName: 'Platform' })*/}
        {/*  }*/}
        {/*>*/}
        {/*  Pricing*/}
        {/*</Link>*/}

        <Accordion
          className="accordion--lg font-heading"
          activeKey={activeAccordion}
          elementKey="TalentNetwork"
          title="Talent Network"
          handleChange={handleAccordionChange}
          titleClass={styles.mobileNavTitle}
        >
          <TalentNetworkDropdown {...talentNetworkData} />
        </Accordion>

        <Link href="/login/" className={clsx('font-heading', styles.menuItem)}>
          Login
        </Link>

        <div
          className={clsx(
            'grid grid-cols-1 md:grid-cols-2 justify-items-stretch gap-4 | fixed bottom-6 left-5 right-5  |  transition-all',
            activeAccordion && 'translate-y-20 | opacity-0 | invisible'
          )}
        >
          {secondaryCta ? (
            <Link
              href={secondaryCta.url}
              className="btn-secondary | btn-size-default whitespace-nowrap rounded-md"
              onClick={() =>
                dataLayerCtaClickEvent({
                  ctaName: secondaryCta.title,
                  sectionName: 'Header'
                })
              }
            >
              {secondaryCta.title}
            </Link>
          ) : null}
          {primaryCta ? (
            <Link
              href={primaryCta.url}
              className="btn-primary | btn-size-default whitespace-nowrap rounded-md"
              onClick={() =>
                dataLayerCtaClickEvent({
                  ctaName: primaryCta.title,
                  sectionName: 'Header'
                })
              }
            >
              {primaryCta.title}
            </Link>
          ) : null}
        </div>
      </nav>
    </section>
  )
}

export default MobileHeader
