import { ReactNode } from 'react'
import clsx from 'clsx'

/* blocks */
import Header from '@/cmp/molecules/Header/Header'
import SEO from '@/molecules/SEO/SEO'
import Footer from '@/cmp/molecules/Footer/Footer'
import {
  platformData,
  solutionsData,
  resourcesData,
  talentNetworkData
} from '@/cmp/molecules/Header/Header.utils'

/* utils */
import strapiData from '@/data/cmp/strapi_data.json'
import { getIndividualServicePageUrl } from '@/utils/functions/utils'

import { articulatCF, onest } from '@/styles/fonts/fonts'

interface Meta {
  property: string
  content: string
}

interface Props {
  children: ReactNode
  title: string
  meta?: Meta[]
  canonical?: string
  primaryCta?: { title: string; url: string }
  secondaryCta?: { title: string; url: string }
  tertiaryCta?: { title: string; url: string }
  jsonSchema?: LDJsonSchema[]
  showNavLinks?: boolean
  noIndex?: boolean
  locales?: string[]
  mode?: 'dark' | 'light'
}

const seoServices = strapiData.data.seo_services.data.map(item => ({
  page_title: item.attributes.page_title,
  slug: getIndividualServicePageUrl('text', item.attributes.slug)
}))
const localization = strapiData.data.localization.data.map(item => ({
  page_title: item.attributes.page_title,
  slug: getIndividualServicePageUrl('text', item.attributes.slug)
}))
const services = strapiData.data.services.data.map(item => ({
  page_title: item.attributes.page_title,
  slug: item.attributes.slug
}))
const resources = strapiData.data.resources.data.map(item => ({
  page_title: item.attributes.page_title,
  slug: item.attributes.slug
}))
const demand_gen_contents = strapiData.data.demand_gen_contents.data.map(
  item => ({
    page_title: item.attributes.page_title,
    slug: getIndividualServicePageUrl('text', item.attributes.slug)
  })
)
const videos = strapiData.data.videos.data.map(item => ({
  page_title: item.attributes.page_title,
  slug: getIndividualServicePageUrl('text', item.attributes.slug)
}))
const languages = strapiData.data.languages.data.map(item => ({
  page_title: item.attributes.page_title,
  slug: getIndividualServicePageUrl('text', item.attributes.slug)
}))

function Layout({
  title,
  meta,
  children,
  canonical = '',
  jsonSchema,
  primaryCta,
  secondaryCta,
  tertiaryCta,
  showNavLinks = true,
  noIndex = false,
  locales,
  mode = 'dark'
}: Props) {
  return (
    <div
      className={clsx(articulatCF.variable, onest.variable, onest.className)}
    >
      <SEO
        title={title}
        meta={meta}
        canonical={canonical}
        jsonSchema={jsonSchema}
        noIndex={noIndex}
        locales={locales}
      />
      <Header
        showNavLinks={showNavLinks}
        primaryCta={primaryCta}
        secondaryCta={secondaryCta}
        tertiaryCta={tertiaryCta}
        platformData={platformData}
        solutionsData={solutionsData}
        resourcesData={resourcesData}
        talentNetworkData={talentNetworkData}
        mode={mode}
      />
      <main data-test-id="main">{children}</main>

      {showNavLinks && (
        <Footer
          seoServices={seoServices}
          localization={localization}
          services={services}
          resources={resources}
          demand_gen_contents={demand_gen_contents}
          videos={videos}
          languages={languages}
        />
      )}
    </div>
  )
}

export default Layout
